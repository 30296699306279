<template>
    <!-- 客户管理》客户与通道公司关联管理 -->
    <div id="app_user_channel_company_list">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="prop_user_channel_company_add_i ++" size="mini">
                    增加
                </el-button>
            </div>

            <div class="head_top_title">客户与通道公司关联</div>

        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            
             <!-- 查询条件 -->
            <el-row id="query_condition_id" style="padding:0px 0;">
                <el-form  :model="form_data" :inline="true" style="margin-bottom: 0px;" >
                    

                    <el-form-item label="客户公司">
                        <el-select v-model="form_data.user_company_id" filterable clearable  size="mini" style="min-width:200px"> 
                            <el-option
                                v-for="item in companyList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                                >
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="通道公司">
                        <el-select v-model="form_data.channel_company_id" filterable size="mini" style="min-width:200px"> 
                            <el-option
                                v-for="item in channelCompanyList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    

                    <el-form-item>
                        <el-button type="primary" @click="submit_cha_xun()" size="mini">查询</el-button>
                    </el-form-item>


                </el-form>
                
                
            </el-row>

            <el-row style="overflow: auto;" :style="{height: ((content_heigth-pagination_height-query_condition_height)+'px')}">
                <el-table  :data="tableData" :show-header="true" border style="width: 100%" size="mini" >
                    <el-table-column  prop="user_company_name" label="客户公司名" min-width="100"  align="center"> </el-table-column>
                    <el-table-column  prop="channel_company_name" label="通道公司名" min-width="100"  align="center"> </el-table-column>
                    <el-table-column  prop="login" label="运营人员" min-width="100"  align="center"> </el-table-column>
                    <el-table-column  prop="createtime" label="时间" min-width="80"  align="center"> </el-table-column>
                    <el-table-column   label="操作"  min-width="50" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" style="color:#f56c6c"  @click="del(scope.row.user_company_id,scope.row.channel_company_id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>

            <el-row id="pagination_id" style="padding:10px 0 0 0;text-align:center;line-height:26px;">
                <!-- 分页  layout="total, sizes, prev, pager, next, jumper"   small-->
                <el-pagination background  
                    layout="total, prev, pager, next,jumper"
                    :total="tableData_total"
                    small
                    :pager-count="5"
                    @size-change="pageSizeChange"
                    @current-change="pageCurrentChange"
                    :page-sizes="[5,10, 20, 30, 40,100]"
                     :page-size="pageSize"
                     style="white-space: normal !important;">
                </el-pagination>
            </el-row>
            

        </el-row>

        
        

    <!-- 客户与通道公司关联增加组件 -->
    <UserChannelCompanyAdd :prop_change_i="prop_user_channel_company_add_i"
        @result="submit_cha_xun"
      ></UserChannelCompanyAdd>


    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import UserChannelCompanyAdd from './user_channel_company_add.vue';//组件 客户与通道公司关联增加

//import axios from "axios";
export default {

    components: {
        UserChannelCompanyAdd,
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            
            companyList:[],//客户公司列表数据-请求接口获取
            channelCompanyList:[],//收款公司数据-请求接口获取

            form_data:{
                user_company_id:0,//查询条件-客户公司id 空字符表示全部
                channel_company_id:"",//查询条件-通道公司id
            },

            indexPage:1,//分页 第几页
            pageSize:10,//分页 一页的数量值
            
            tableData:[],//表格数据
            tableData_total:0,//表格数据总量
            pagination_height:40,//分页的高度
            query_condition_height:40,//查询条件的高度



            //客户与通道公司关联增加
            prop_user_channel_company_add_i:0,//客户与通道公司关联增加  组件 弹出框是否可见

        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        
        // this.companyList = this.$options.data().companyList;//单个恢复初始化
        // this.channelCompanyList = this.$options.data().channelCompanyList;//单个恢复初始化
        // this.form_data = this.$options.data().form_data;//单个恢复初始化
        // this.indexPage = this.$options.data().indexPage;//单个恢复初始化
        // this.pageSize = this.$options.data().pageSize;//单个恢复初始化
        // this.tableData = this.$options.data().tableData;//单个恢复初始化
	    // this.tableData_total = this.$options.data().tableData_total;//单个恢复初始化

        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));

        //初始化设置
        this.initSetUp();

        //查询
        this.submit_cha_xun();

        //获取公司列表数据-请求接口获取
        this.getCompanyList();

        //获取收款和付款公司数据-请求接口获取
        this.getChannelCompanyList();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);

        },

        //获取客户公司列表数据
        getCompanyList(){
            //请求接口
            API.CompanyServlet({
                param: "selList",
                searchID: "",
            }).then((res) => {
                if (res.recode === 0) {
                    this.companyList = res.list;//公司列表数据-请求接口获取
                    var obj = {value:0,name:"全部"};
                    this.companyList.unshift(obj);
                }
            });
        },

        //获取收款和付款公司数据
        getChannelCompanyList(){
            //请求接口
            API.ChannelCompanyServlet({
                param: "listAI"
            }).then((res) => {
                if (res.recode === 0) {
                    this.channelCompanyList = res.list//收款公司数据-请求接口获取
                    var obj = {value:"",name:"全部"};
                    this.channelCompanyList.unshift(obj);
                }
            });
        },

        //查询
        submit_cha_xun(){


            //请求接口
            API.UserServlet({
                param: "userChannelCompanyList",
                user_company_id:this.form_data.user_company_id,
                channel_company_id:this.form_data.channel_company_id,
               
               
                indexPage:this.indexPage,
                pageSize:this.pageSize,

            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {

                    this.tableData = res.list;
                    this.tableData_total = res.totalNum;

                    this.$nextTick(function(){
                        //获取分页的高度
                        var pagination = document.getElementById('pagination_id');
                        if(!API.isEmtry(pagination)){
                            this.pagination_height = pagination.clientHeight;
                        }
                        //查询条件的高度
                        var query_condition = document.getElementById('query_condition_id');
                        if(!API.isEmtry(query_condition)){
                            this.query_condition_height = query_condition.clientHeight;
                        }
                    });
                } 
            });
        },
        //分页大小发生改变调用
        pageSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageSize = val;
            //查询-- 请求接口
            this.submit_cha_xun();
        },
        //分页 当前页 发生改变调用
        pageCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.indexPage = val;
            //查询-- 请求接口
            this.submit_cha_xun();
        },

        //删除
        del(user_company_id,channel_company_id){
            this.$confirm('此操作将永久删除该客户与通道公司关联, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                    //请求接口
                    API.UserServlet({
                        param: "userChannelCompanyDel",
                        user_company_id:user_company_id,
                        channel_company_id:channel_company_id
                    }).then((res) => {
                        if (res.recode === 0) {
                            this.$message({showClose: true,message: '恭喜你，客户与通道公司关联删除成功', type: 'success'});
                            //重新加载数据
                            this.submit_cha_xun();
                        }
                    });
            }).catch(() => {//已取消删除
            });
        },
        

        //返回
        goBack() {
            var page_type = this.$route.params.page_type;
            // API.router_to("/channel_index");
            API.router_to("/"+page_type);
        }
    }
};

</script>

<style scoped>

#app_state_list>>>.el_descriptions_label_class{
    text-align: center;
    width: 80px;
    
}

/*form表单样式调整*/
#app_state_list>>>.el-form-item {
  margin-bottom: 0px;
}

</style>